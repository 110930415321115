<template>
  <div>
    <crud-table
      ref="crudTable"
      :list-items="listItems"
      :is-editable="false"
      :is-disabled-deleted-filter="false"
      :is-restorable="false"
      :is-deleteble="true"
      :show-brands-filter="true"
      :show-search-filter="true"
      :show-sent-filter="true"
      :show-sync-filter="true"
      :brand-list="brandList"
      :show-create="false"
      is-invoices
      heading="Reverse invoices"
      endpoint="reverse-invoices"
      row-click-path="/tables/reverse-invoices"
      @delete="deleteInvoice"
    />
  </div>
</template>

<script>
import CrudTable from '../../../modules/admin/CrudTable.vue'
import AddCustomInvoice from '../../../modules/components/AddCustomInvoice.vue'
import Request from '../../../helpers/request'
import Brands from '../../../helpers/brands'
import { TEXT_FIELD_MAX_LENGTH } from '../../../constants/common'

export default {
  components: { CrudTable, AddCustomInvoice },
  mixins: [Request, Brands],
  data () {
    return {
      valid: true,
      brandList: [],
      listItems: [
        { text: 'ID', value: 'id' },
        // { text: 'Brand', value: 'brand', textValue: 'name', sortable: false },
        // { text: 'customer address', value: 'address' },
        // { text: 'customer name', value: 'passengerName' },
        // { text: 'po number', value: 'poNumber' },
        { text: 'invoice number', value: 'number' },
        // { text: 'driver name', value: 'driverName' },
        { text: 'ride id', value: 'ride', textValue: 'id' },
        { text: 'Sent at', value: 'sentAt' },
        // { text: 'Sync status', value: 'exactSyncStatus' },
        // { text: 'Last synced at', value: 'exactSyncedAt' },
        // { text: 'Last sync error', value: 'exactSyncError' },
        { text: 'PDF', value: 'pdf', pdf: true, pdfIcon: true, sortable: false },
        { text: '', value: null }
      ],
      TEXT_FIELD_MAX_LENGTH,
      loading: true,
      data: [],
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {}
      }
    }
  },
  async beforeMount () {
    try {
      this.brandList = await this.fetchBrands()
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    // isShowSyncButton (invoice) {
    //   return invoice && invoice.exactCanSync
    // },
    // async syncInvoice (invoiceId) {
    //   await this.request('POST', `/invoices/${invoiceId}/sync`, {})
    //   this.$refs.crudTable.requestData()
    // },
    deleteInvoice (id) {
      this.request('DELETE', `/reverse-invoices/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData()
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Invoice has been deleted'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
